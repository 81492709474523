import React, { Component } from "react";

import { graphql } from "gatsby";

// Page
import Index from '../pages/index'

export const GET_DATA = graphql`
  query GET_DATA ($skip: Int!, $limit: Int!, $name: String!) {
    post: allMarkdownRemark (
        filter: { frontmatter: { templateKey: { eq: "blog-post" }, title: { regex: $name } } }
        sort: { fields: [ frontmatter___date ], order: DESC }
        limit: $limit
        skip: $skip
    ) {
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          featuredimage
          category
        }
        html
      }
    }
  },
  categories: allMarkdownRemark(filter: { frontmatter: {templateKey: {eq: "blog-category"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            featuredimage
          }
        }
      }
    }
  }
`;


export const HOC = (WrapperComponent) => {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                first_post: {},
                posts: [],
                categories: []
            }
        }

        componentDidMount() {
            let { data: { post, categories} } = this.props;

            const copy_post = this.post_constructor(post.edges.slice());
            const copy_categories = this.category_constructor(categories.edges.slice());
            copy_post.forEach((p) => {
                const cat = copy_categories.find((c) => {
                    return c.title === p.category
                });
                p.category = cat;
            });


            this.setState({
                posts: copy_post,
                categories: copy_categories,
                first_post: copy_post.shift()
            });

        }


        post_constructor = (posts) => {
            let array_post = posts.map((element) => {
                const { node: { frontmatter: { category, date, description, featuredimage, title }, html, id } } = element;
                return {
                    id,
                    category,
                    date,
                    title,
                    featuredimage,
                    description,
                    html
                }
            });
            return array_post;
        };
        category_constructor = (categories) => {
            let array_categories = categories.map((element) => {
                const { node: { frontmatter: { description, featuredimage, title }, id } } = element;
                return {
                    id,
                    title,
                    featuredimage,
                    description
                }
            });
            return array_categories;
        };

        render() {
            return <WrapperComponent {...this.props} info={this.state} />
        }
    }
};

export const CMSData = (props) => <Index { ...props }/> ;
const CMSDataSubscription = HOC(CMSData);
export default CMSDataSubscription;
